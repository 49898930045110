import React from 'react';
import img1 from '../../assets/img/NZ/solo1.JPG';
import img2 from '../../assets/img/NZ/solo2.JPG';
import img3 from '../../assets/img/NZ/solo3.JPG';
import img4 from '../../assets/img/NZ/solo4.JPG';
import img5 from '../../assets/img/NZ/solo5.JPG';
import img6 from '../../assets/img/NZ/solo6.JPG';
import img7 from '../../assets/img/NZ/solo7.JPG';
import img8 from '../../assets/img/NZ/solo8.JPG';
import img9 from '../../assets/img/NZ/solo9.JPG';
import img10 from '../../assets/img/NZ/solo10.JPG';
import img11 from '../../assets/img/NZ/solo11.JPG';
import img12 from '../../assets/img/NZ/solo12.JPG';
import img13 from '../../assets/img/NZ/solo13.JPG';
import img14 from '../../assets/img/NZ/solo14.JPG';
import img15 from '../../assets/img/NZ/solo15.JPG';
import img16 from '../../assets/img/NZ/solo16.JPG';
import img17 from '../../assets/img/NZ/solo17.JPG';
import img18 from '../../assets/img/NZ/solo18.JPG';
import img19 from '../../assets/img/NZ/solo19.JPG';
import img20 from '../../assets/img/NZ/solo20.JPG';
import img21 from '../../assets/img/NZ/solo21.JPG';
import img22 from '../../assets/img/NZ/solo22.JPG';
import img23 from '../../assets/img/NZ/solo23.JPG';
import img24 from '../../assets/img/NZ/solo24.JPG';
import img25 from '../../assets/img/NZ/solo25.JPG';
import img26 from '../../assets/img/NZ/solo26.JPG';
import img27 from '../../assets/img/NZ/solo27.JPG';
import img28 from '../../assets/img/NZ/solo28.JPG';
import img29 from '../../assets/img/NZ/solo29.JPG';
import img30 from '../../assets/img/NZ/solo30.JPG';
import img31 from '../../assets/img/NZ/solo31.JPG';
import img32 from '../../assets/img/NZ/solo32.JPG';
import img33 from '../../assets/img/NZ/solo33.JPG';
import img34 from '../../assets/img/NZ/solo34.JPG';
import img35 from '../../assets/img/NZ/solo35.JPG';
import img36 from '../../assets/img/NZ/solo36.JPG';
import img37 from '../../assets/img/NZ/solo37.JPG';
import img38 from '../../assets/img/NZ/solo38.JPG';
import img39 from '../../assets/img/NZ/solo39.JPG';
import img40 from '../../assets/img/NZ/solo40.JPG';
import img41 from '../../assets/img/NZ/solo41.JPG';
import img42 from '../../assets/img/NZ/solo42.JPG';
import img43 from '../../assets/img/NZ/solo43.JPG';
import img44 from '../../assets/img/NZ/solo44.JPG';
import img45 from '../../assets/img/NZ/solo45.JPG';
import img46 from '../../assets/img/NZ/solo46.JPG';
import img47 from '../../assets/img/NZ/solo47.JPG';
import img48 from '../../assets/img/NZ/solo48.JPG';
import img49 from '../../assets/img/NZ/solo49.JPG';
import img50 from '../../assets/img/NZ/solo50.JPG';
import img51 from '../../assets/img/NZ/solo51.JPG';
import img52 from '../../assets/img/NZ/solo52.JPG';
import img53 from '../../assets/img/NZ/solo53.JPG';
import img54 from '../../assets/img/NZ/solo54.JPG';
import img55 from '../../assets/img/NZ/solo55.JPG';
import img56 from '../../assets/img/NZ/solo56.JPG';
import img57 from '../../assets/img/NZ/solo57.JPG';
import img58 from '../../assets/img/NZ/solo58.JPG';
import img59 from '../../assets/img/NZ/solo59.JPG';
import img60 from '../../assets/img/NZ/solo60.JPG';
import img61 from '../../assets/img/NZ/solo61.JPG';
import img62 from '../../assets/img/NZ/solo62.JPG';
import img63 from '../../assets/img/NZ/solo63.JPG';
import img64 from '../../assets/img/NZ/solo64.JPG';
import img65 from '../../assets/img/NZ/solo65.JPG';
import img66 from '../../assets/img/NZ/solo66.JPG';
import img67 from '../../assets/img/NZ/solo67.JPG';
import img68 from '../../assets/img/NZ/solo68.JPG';
import img69 from '../../assets/img/NZ/solo69.JPG';
import img70 from '../../assets/img/NZ/solo70.JPG';
import img71 from '../../assets/img/NZ/solo71.JPG';
import img72 from '../../assets/img/NZ/solo72.JPG';


export default function NewZealand(props) {

    return ( 
        <div className="used-project">
            <div className="box1">
                <img alt="" className="lg" src={img1}></img>
                <img alt="" className="lg" src={img2}></img>
                <img alt="" className="sm" src={img3}></img>
            </div>
            <div className="box1">
                <img alt="" className="lg" src={img4}></img>
                <img alt="" className="lg" src={img5}></img>
                <img alt="" className="sm" src={img6}></img>
            </div>
            <div className="box1">
                <img alt="" className="lg" src={img7}></img>
                <img alt="" className="sm" src={img8}></img>
                <img alt="" className="lg" src={img9}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img10}></img>
                <img alt="" className="lg" src={img11}></img>
                <img alt="" className="lg" src={img12}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img13}></img>
                <img alt="" className="lg" src={img14}></img>
                <img alt="" className="sm" src={img15}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img16}></img>
                <img alt="" className="sm" src={img17}></img>
                <img alt="" className="sm" src={img18}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img19}></img>
                <img alt="" className="sm" src={img20}></img>
                <img alt="" className="sm" src={img21}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img22}></img>
                <img alt="" className="sm" src={img23}></img>
                <img alt="" className="sm" src={img24}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img25}></img>
                <img alt="" className="sm" src={img26}></img>
                <img alt="" className="sm" src={img27}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img28}></img>
                <img alt="" className="sm" src={img29}></img>
                <img alt="" className="sm" src={img30}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img31}></img>
                <img alt="" className="sm" src={img32}></img>
                <img alt="" className="sm" src={img33}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img34}></img>
                <img alt="" className="sm" src={img35}></img>
                <img alt="" className="sm" src={img36}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img37}></img>
                <img alt="" className="sm" src={img38}></img>
                <img alt="" className="sm" src={img39}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img40}></img>
                <img alt="" className="sm" src={img41}></img>
                <img alt="" className="sm" src={img42}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img43}></img>
                <img alt="" className="sm" src={img44}></img>
                <img alt="" className="sm" src={img45}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img46}></img>
                <img alt="" className="sm" src={img47}></img>
                <img alt="" className="sm" src={img48}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img49}></img>
                <img alt="" className="sm" src={img50}></img>
                <img alt="" className="sm" src={img51}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img52}></img>
                <img alt="" className="sm" src={img53}></img>
                <img alt="" className="sm" src={img54}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img55}></img>
                <img alt="" className="sm" src={img56}></img>
                <img alt="" className="sm" src={img57}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img58}></img>
                <img alt="" className="sm" src={img59}></img>
                <img alt="" className="sm" src={img60}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img61}></img>
                <img alt="" className="sm" src={img62}></img>
                <img alt="" className="sm" src={img63}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img64}></img>
                <img alt="" className="sm" src={img65}></img>
                <img alt="" className="sm" src={img66}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img67}></img>
                <img alt="" className="sm" src={img68}></img>
                <img alt="" className="sm" src={img69}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img70}></img>
                <img alt="" className="sm" src={img71}></img>
                <img alt="" className="sm" src={img72}></img>
            </div>
        </div>
    )
};