import React from 'react';
import GracePic from '../../assets/GRACE.jpg';


export default function Elise(props) {

    return ( 
        <div className="about-page">
            <div className="about-pic">
                <img id="profile-pic" className="sml" alt="profile" src={GracePic}></img>
            </div>
            <div className="bio">
            Grace Beck is a wardrobe stylist based in NYC. 
            <br />
            </div>
        </div>
    )
};