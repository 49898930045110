import React, { useState } from 'react';
import img1 from '../../assets/img/EekZine/eek1.JPG';
import img3 from '../../assets/img/EekZine/eek3.JPG';
import img5 from '../../assets/img/EekZine/eek5.JPG';
import img6 from '../../assets/img/EekZine/eek6.JPG';
import img8 from '../../assets/img/EekZine/eek8.JPG';
import img9 from '../../assets/img/EekZine/eek9.JPG';
import img10 from '../../assets/img/EekZine/eek10.JPG';
import img11 from '../../assets/img/EekZine/eek11.JPG';
import img13 from '../../assets/img/EekZine/eek13.JPG';
import img16 from '../../assets/img/EekZine/eek16.JPG';
import img17 from '../../assets/img/EekZine/eek17.JPG';
import img18 from '../../assets/img/EekZine/eek18.JPG';
import img19 from '../../assets/img/EekZine/eek19.JPG';
import img20 from '../../assets/img/EekZine/eek20.JPG';
import img21 from '../../assets/img/EekZine/eek21.JPG';
import img22 from '../../assets/img/EekZine/eek22.JPG';
import img23 from '../../assets/img/EekZine/eek23.JPG';
import img24 from '../../assets/img/EekZine/eek24.JPG';

import arrow from '../../assets/arrow2.png';
import arrowdown from '../../assets/arrowdown.png';

export default function Eek(props) {

    const [opened, setOpened] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();

        if (!opened) {
            setOpened(true);
        } else {
            setOpened(false);
        }
    }

    return ( 
        <div className="used-project">
            <div className="box1">
                <img alt="" className="medium" src={img1}></img>
                <img alt="" className="sm" src={img3}></img>
                <img alt="" className="medium" src={img5}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img6}></img>
                <img alt="" className="medium" src={img8}></img>
                <img alt="" className="sm" src={img9}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img10}></img>
                <img alt="" className="xxl" src={img11}></img>
                <img alt="" className="sm" src={img13}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img16}></img>
                <img alt="" className="sm" src={img17}></img>
                <img alt="" className="sm" src={img18}></img>
            </div>
            <div className="box1">
                <img alt="" className="medium" src={img19}></img>
                <img alt="" className="xxl" src={img20}></img>
                <img alt="" className="sm" src={img21}></img>
            </div>
            <div className="box1">
                <img alt="" className="xxl" src={img22}></img>
                <img alt="" className="sm" src={img23}></img>
                <img alt="" className="xxl" id="bottom-pic" src={img24}></img>
            </div>
            <footer className="projfoot">
                <div className="proj-foot-div">
                    <div className={opened ? "opened":"closed active"}>
                        
                        <button className="details-btn" onClick={handleClick}><img alt="clickable arrow" src={opened ? arrowdown : arrow }></img></button>
                        <h4 id="foot-head" className="footer-header">EEK! Zine — Photography</h4>
                        <div className="footer-content">
                            <p>
                            <br />
                            Based in Seattle, WA, EEK! Zine publishes writing inspired by contributed visual art. Poets know this technique as ekphrasis, which is the term at the root of the magazine’s title. This shoot will be used as cover art.
                            <br />
                            <br />
                            <a href="https://spencermg.com/eek" className="web-link" target="noopener">https://spencermg.com/eek</a>
                            <br />
                            <br />
                            </p> 
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
};