import React, { useState } from 'react';
import img1 from '../../assets/img/Nourished_8/foodshot1.JPG';
import img2 from '../../assets/img/Nourished_8/foodshot2.JPG';
import img3 from '../../assets/img/Nourished_8/foodshot3.JPG';
import img4 from '../../assets/img/Nourished_8/foodshot4.JPG';
import img5 from '../../assets/img/Nourished_8/foodshot5.JPG';
import img6 from '../../assets/img/Nourished_8/foodshot6.JPG';
import img7 from '../../assets/img/Nourished_8/foodshot7.JPG';
import img8 from '../../assets/img/Nourished_8/foodshot8.JPG';
import img9 from '../../assets/img/Nourished_8/foodshot9.JPG';
import img10 from '../../assets/img/Nourished_8/foodshot10.JPG';
import img11 from '../../assets/img/Nourished_8/foodshot11.JPG';
import img12 from '../../assets/img/Nourished_8/foodshot12.JPG';
import img13 from '../../assets/img/Nourished_8/foodshot13.JPG';
import img14 from '../../assets/img/Nourished_8/foodshot14.JPG';
import img15 from '../../assets/img/Nourished_8/foodshot15.JPG';
import img16 from '../../assets/img/Nourished_8/foodshot16.JPG';
import img17 from '../../assets/img/Nourished_8/foodshot17.JPG';
import img18 from '../../assets/img/Nourished_8/foodshot18.JPG';
import img19 from '../../assets/img/Nourished_8/foodshot19.JPG';
import img20 from '../../assets/img/Nourished_8/foodshot20.JPG';
import img21 from '../../assets/img/Nourished_8/foodshot21.JPG';
import img22 from '../../assets/img/Nourished_8/foodshot22.JPG';
import img23 from '../../assets/img/Nourished_8/foodshot23.JPG';
import img24 from '../../assets/img/Nourished_8/foodshot24.JPG';
import img25 from '../../assets/img/Nourished_8/foodshot25.JPG';
import img26 from '../../assets/img/Nourished_8/foodshot26.JPG';
import img27 from '../../assets/img/Nourished_8/foodshot27.JPG';
import img28 from '../../assets/img/Nourished_8/foodshot28.JPG';
import img29 from '../../assets/img/Nourished_8/foodshot29.JPG';
import img30 from '../../assets/img/Nourished_8/foodshot30.JPG';
import img31 from '../../assets/img/Nourished_8/foodshot31.JPG';
import img32 from '../../assets/img/Nourished_8/foodshot32.JPG';

import arrow from '../../assets/arrow2.png';
import arrowdown from '../../assets/arrowdown.png';

export default function Nourished(props) {

    const [opened, setOpened] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();

        if (!opened) {
            setOpened(true);
        } else {
            setOpened(false);
        }
    }

    return ( 
        <div className="used-project">
            <div className="box1">
                <img alt="" className="sm" src={img1}></img>
                <img alt="" className="sm" src={img2}></img>
                <img alt="" className="sm" src={img3}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img4}></img>
                <img alt="" className="sm" src={img5}></img>
                <img alt="" className="sm" src={img6}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img7}></img>
                <img alt="" className="sm" src={img8}></img>
                <img alt="" className="sm" src={img9}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img10}></img>
                <img alt="" className="sm" src={img11}></img>
                <img alt="" className="sm" src={img12}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img13}></img>
                <img alt="" className="sm" src={img14}></img>
                <img alt="" className="sm" src={img15}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img16}></img>
                <img alt="" className="sm" src={img17}></img>
                <img alt="" className="sm" src={img18}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img19}></img>
                <img alt="" className="sm" src={img20}></img>
                <img alt="" className="sm" src={img21}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img22}></img>
                <img alt="" className="sm" src={img23}></img>
                <img alt="" className="sm" src={img24}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img25}></img>
                <img alt="" className="sm" src={img26}></img>
                <img alt="" className="sm" src={img27}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img28}></img>
                <img alt="" className="sm" src={img29}></img>
                <img alt="" className="sm" src={img30}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img31}></img>
                <img alt="" className="sm" id="bottom-pic" src={img32}></img>
            </div>
            <footer className="projfoot">
                <div className="proj-foot-div">
                    <div className={opened ? "opened":"closed active"}>
                        
                        <button className="details-btn" onClick={handleClick}><img alt="clickable arrow" src={opened ? arrowdown : arrow }></img></button>
                        <h4 id="foot-head" className="footer-header">Nourished — Photography + Styling</h4>
                        <div className="footer-content">
                            <p>
                            <br />
                            Nourished was a personal project, with the goal of naming and honoring food for the gift that it is to our bodies, minds and hearts. 
                            <br />
                            Models: Henry Beck, Molly Stuart, Nicole.
                            <br />
                            <br />
                            </p> 
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
};