import React, { useState } from 'react';
import movie7 from '../../assets/img/Blue_Tape_Final.mov'
import arrow from '../../assets/arrow2.png';
import arrowdown from '../../assets/arrowdown.png';

export default function BlueTape(props) {

    
    const [opened, setOpened] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();

        if (!opened) {
            setOpened(true);
        } else {
            setOpened(false);
        }
    }

    return ( 
        <div className="used-project">
            <div className="box1">
            <video className="xxlarge more-space" controls autoPlay loop>
                <source src={movie7} ></source>
            </video>
            </div>

            <footer className="projfoot">
                <div className="proj-foot-div">
                    <div className={opened ? "opened":"closed active"}>
                        
                        <button className="details-btn" onClick={handleClick}><img alt="clickable arrow" src={opened ? arrowdown : arrow }></img> </button>
                        <h4 id="foot-head" className="footer-header">Blue Tape</h4>
                        <div className="footer-content">
                            <p>
                            
                            Directed and Shot by Christopher Behnen @ChristopherBehnen
                            Art Directed and Featuring Grace Beck @gracekbeck
                            <br />
                            <br />
                            Models:
                            Jessica Manning @jessica_manning
                            Nate Kay @natedkay
                            Ella Paine @ellampaine
                            Genevieve Wilson @apricot.floral 
                            <br />
                            <br />
                            Crew:
                            First AC - DI” Ishida @ac_named_di
                            Second AC - Caleb Genheimer @calebgenheimer
                            Gaffer - Dan Rippl @danrippl
                            BTS Photographer - Greta Besser @gretabesser
                            Custom score by - Hank Donato @hanksbutnohanks
                            <br />
                            <br />
                            <br />
    
                            
                            </p> 
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
};