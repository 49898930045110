import React from 'react';
import { Link } from "react-router-dom";
import logo from '../content/assets/img/logo.PNG';

export default function Header(props) {

    return (
        <div className="navbar">
            <div className="header-logo">
                <span className="spacer">{/* <-- hidden spacer to center brand on mobile --> */}</span>
                <Link to="/" className="App-link">
                        <img alt="Grace K Beck" src={logo} />
                </Link>
            </div>
            <div className="navigate">
                 <ul className="navigate-two">
                     <li className="nav-item">
                        <Link to="/works" className="menu-item">Work
                        </Link>
                        {/* <a href="#" className="menu-item">Works</a> */}
                     </li>
                     <li className="nav-item">
                        <Link to="/about" className="menu-item">About
                        </Link>
                     </li>
                     <li className="nav-item">
                        <Link to="/contact" className="menu-item">Contact
                        </Link>
                     </li>
                 </ul>
             </div>
        </div>





        // <nav class="navbar navbar-expand-lg">
        //     <div className="d-flex flex-grow-1 header-logo">
        //         <span class="w-100 d-lg-none d-block">
        //             {/* <-- hidden spacer to center brand on mobile --> */}
        //             </span>
        //         <a className="navbar-brand d-none d-lg-inline-block" href="#">
        //             <img src={logo} />
        //         </a>
        //     </div>
        //         {/* <div class="w-100 text-right">
        //             <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#myNavbar">
        //                 <span class="navbar-toggler-icon"></span>
        //             </button>
        //         </div> */}
        //     <div class="collapse header-nav navbar-collapse flex-grow-1 text-right">
        //         <ul class="navbar-nav ml-auto flex-nowrap">
        //             <li class="nav-item dropdown">
        //                 <a class="nav-link m-2 menu-item dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        //                 Works
        //                 </a>
        //             </li>
        //             <li class="nav-item">
        //                 <a href="http://gracebeck.co/about" class="nav-link m-2 menu-item">About</a>
        //             </li>
        //             <li class="nav-item">
        //                 <a href="http://gracebeck.co/contact" class="nav-link m-2 menu-item">Contact</a>
        //             </li>
        //         </ul>
        //     </div>
        // </nav>
    )
};