import React, { useState } from 'react';
import img1 from '../../assets/img/Rich_4/rich1.JPG';
import img2 from '../../assets/img/Rich_4/rich2.JPG';
import img3 from '../../assets/img/Rich_4/rich3.JPG';
import img4 from '../../assets/img/Rich_4/rich4.JPG';
import img5 from '../../assets/img/Rich_4/rich5.JPG';
import img6 from '../../assets/img/Rich_4/rich6.JPG';
import img7 from '../../assets/img/Rich_4/rich7.JPG';
import img8 from '../../assets/img/Rich_4/rich8.JPG';
import img9 from '../../assets/img/Rich_4/rich9.JPG';
import img10 from '../../assets/img/Rich_4/rich10.JPG';
import img11 from '../../assets/img/Rich_4/rich11.JPG';
import img12 from '../../assets/img/Rich_4/rich12.JPG';
import img13 from '../../assets/img/Rich_4/rich13.JPG';
import img14 from '../../assets/img/Rich_4/rich14.JPG';
import img15 from '../../assets/img/Rich_4/rich15.JPG';
import img16 from '../../assets/img/Rich_4/rich16.JPG';
import img17 from '../../assets/img/Rich_4/rich17.JPG';

import arrow from '../../assets/arrow2.png';
import arrowdown from '../../assets/arrowdown.png';

export default function Rich(props) {

    const [opened, setOpened] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();

        if (!opened) {
            setOpened(true);
        } else {
            setOpened(false);
        }
    }

    return ( 
        <div className="used-project">
            <div className="box1">
                <img  alt="" className="smallest" src={img1}></img>
                <img alt=""  className="unknown" src={img2}></img>
                <img  alt="" className="smallest" src={img3}></img>
            </div>
            <div className="box1">
                <img  alt="" className="smallest" src={img4}></img>
                <img  alt="" className="smallest" src={img5}></img>
                <img  alt="" className="smallest" src={img6}></img>
            </div>
            <div className="box1">
                <img  alt="" className="smallest" src={img7}></img>
                <img alt=""  className="unknown" src={img8}></img>
                <img  alt="" className="smallest" src={img9}></img>
            </div>
            <div className="box1">
                <img  alt="" className="smallest" src={img10}></img>
                <img  alt="" className="smallest" src={img11}></img>
                <img  alt="" className="smallest" src={img12}></img>
            </div>
            <div className="box1">
                <img  alt="" className="smallest" src={img13}></img>
                <img  alt="" className="smallest" src={img14}></img>
                <img  alt="" className="smallest" src={img15}></img>
            </div>
            <div className="box1">
                <img  alt="" className="smallest" src={img16}></img>
                <img alt=""  className="unknown"  id="bottom-pic" src={img17}></img>
            </div>
            <footer className="projfoot">
                <div className="proj-foot-div">
                    <div className={opened ? "opened":"closed active"}>
                        
                        <button className="details-btn" onClick={handleClick}><img alt="clickable arrow" src={opened ? arrowdown : arrow }></img></button>
                        <h4 id="foot-head" className="footer-header">RICH — Photography + Styling</h4>
                        <div className="footer-content">
                            <p>
                            <br />
                            Model: Richard B. 
                            <br />
                            Photo: Grace Beck
                            <br />
                            <br />
                            </p> 
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
};