import React, { useState } from 'react';
import img1 from '../../assets/img/TandT/T+T_GraceBeck_-1.JPG';
import img2 from '../../assets/img/TandT/T+T_GraceBeck_-2.JPG';
import img3 from '../../assets/img/TandT/T+T_GraceBeck_-3.JPG';
import img4 from '../../assets/img/TandT/T+T_GraceBeck_-4.JPG';
import img5 from '../../assets/img/TandT/T+T_GraceBeck_-5.JPG';
import img6 from '../../assets/img/TandT/T+T_GraceBeck_-6.JPG';
import img7 from '../../assets/img/TandT/T+T_GraceBeck_-7.JPG';
import img8 from '../../assets/img/TandT/T+T_GraceBeck_-8.JPG';
import img9 from '../../assets/img/TandT/T+T_GraceBeck_-9.JPG';
import img10 from '../../assets/img/TandT/T+T_GraceBeck_-10.JPG';
import img11 from '../../assets/img/TandT/T+T_GraceBeck_-11.JPG';
import img12 from '../../assets/img/TandT/T+T_GraceBeck_-12.JPG';
import img13 from '../../assets/img/TandT/T+T_GraceBeck_-13.JPG';
import img14 from '../../assets/img/TandT/T+T_GraceBeck_-14.JPG';
import img15 from '../../assets/img/TandT/T+T_GraceBeck_-15.JPG';
import img16 from '../../assets/img/TandT/T+T_GraceBeck_-16.JPG';
import img17 from '../../assets/img/TandT/T+T_GraceBeck_-17.JPG';
import img18 from '../../assets/img/TandT/T+T_GraceBeck_-18.JPG';
import img19 from '../../assets/img/TandT/T+T_GraceBeck_-19.JPG';
import img20 from '../../assets/img/TandT/T+T_GraceBeck_-20.JPG';
import img21 from '../../assets/img/TandT/T+T_GraceBeck_-21.JPG';
import img22 from '../../assets/img/TandT/T+T_GraceBeck_-22.JPG';
import img23 from '../../assets/img/TandT/T+T_GraceBeck_-23.JPG';
import img24 from '../../assets/img/TandT/T+T_GraceBeck_-24.JPG';
import img25 from '../../assets/img/TandT/T+T_GraceBeck_-25.JPG';
import img26 from '../../assets/img/TandT/T+T_GraceBeck_-26.JPG';
import img27 from '../../assets/img/TandT/T+T_GraceBeck_-27.JPG';
import img28 from '../../assets/img/TandT/T+T_GraceBeck_-28.JPG';
import img29 from '../../assets/img/TandT/T+T_GraceBeck_-29.JPG';
import img30 from '../../assets/img/TandT/T+T_GraceBeck_-30.JPG';
import img31 from '../../assets/img/TandT/T+T_GraceBeck_-31.JPG';
import img32 from '../../assets/img/TandT/T+T_GraceBeck_-32.JPG';

import arrow from '../../assets/arrow2.png';
import arrowdown from '../../assets/arrowdown.png';

export default function BoyNeonxRosaRanger(props) {

    const [opened, setOpened] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();

        if (!opened) {
            setOpened(true);
        } else {
            setOpened(false);
        }
    }

    return ( 
        <div className="used-project">
            <div className="box1">
                <img alt="" className="sm" src={img1}></img>
                <img alt="" className="sm" src={img2}></img>
                <img alt="" className="sm" src={img3}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img4}></img>
                <img alt="" className="sm" src={img5}></img>
                <img alt="" className="xxl" src={img6}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img7}></img>
                <img alt="" className="xxl" src={img8}></img>
                <img alt="" className="sm" src={img9}></img>
            </div>
            <div className="box1">
                <img alt="" className="xxl" src={img10}></img>
                <img alt="" className="sm" src={img11}></img>
                <img alt="" className="sm" src={img12}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img13}></img>
                <img alt="" className="sm" src={img14}></img>
                <img alt="" className="xxl" src={img15}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img16}></img>
                <img alt="" className="sm" src={img17}></img>
                <img alt="" className="sm" src={img18}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img19}></img>
                <img alt="" className="sm" src={img20}></img>
                <img alt="" className="sm" src={img21}></img>
            </div>
            <div className="box1">
                <img alt="" className="sqr" src={img22}></img>
                <img alt="" className="xxl" src={img23}></img>
                <img alt="" className="sm" src={img24}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img25}></img>
                <img alt="" className="sm" src={img26}></img>
                <img alt="" className="sm" src={img27}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img28}></img>
                <img alt="" className="sm" src={img29}></img>
                <img alt="" className="sm" src={img30}></img>
            </div>
            <div className="box1">
                <img alt="" className="sqr" src={img31}></img>
                <img alt="" className="sm" id="bottom-pic" src={img32}></img>
            </div>
            <footer className="projfoot">
                <div className="proj-foot-div">
                    <div className={opened ? "opened":"closed active"}>
                        
                        <button className="details-btn" onClick={handleClick}><img alt="clickable arrow" src={opened ? arrowdown : arrow }></img></button>
                        <h4 id="foot-head" className="footer-header">Tess and Tricia — Photography</h4>
                        <div className="footer-content">
                            <p>
                            <br />
                            Styling: MaeMae Co. 
                            <br />
                            Photography: Grace Beck 
                            <br />
                            Models: Sami Weaver, Ashlee Blake
                            <br />
                            Client: Tess and Tricia
                            <br />
                            <br />
                            </p> 
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
};