import React, { useState } from 'react';
import img1 from '../../assets/img/BagguAndLiv/LivWeb_GraceBeck-1.JPG';
import img2 from '../../assets/img/BagguAndLiv/LivWeb_GraceBeck-2.JPG';
import img3 from '../../assets/img/BagguAndLiv/LivWeb_GraceBeck-3.JPG';
import img4 from '../../assets/img/BagguAndLiv/LivWeb_GraceBeck-4.JPG';
import img5 from '../../assets/img/BagguAndLiv/LivWeb_GraceBeck-5.JPG';
import img6 from '../../assets/img/BagguAndLiv/LivWeb_GraceBeck-6.JPG';
import img7 from '../../assets/img/BagguAndLiv/LivWeb_GraceBeck-7.JPG';
import img8 from '../../assets/img/BagguAndLiv/LivWeb_GraceBeck-8.JPG';
import img9 from '../../assets/img/BagguAndLiv/LivWeb_GraceBeck-9.JPG';
import img10 from '../../assets/img/BagguAndLiv/LivWeb_GraceBeck-10.JPG';
import img11 from '../../assets/img/BagguAndLiv/LivWeb_GraceBeck-11.JPG';
import img12 from '../../assets/img/BagguAndLiv/LivWeb_GraceBeck-12.JPG';
import img13 from '../../assets/img/BagguAndLiv/LivWeb_GraceBeck-13.JPG';
import img14 from '../../assets/img/BagguAndLiv/LivWeb_GraceBeck-14.JPG';
import img15 from '../../assets/img/BagguAndLiv/LivWeb_GraceBeck-15.JPG';
import img16 from '../../assets/img/BagguAndLiv/LivWeb_GraceBeck-16.JPG';
import img17 from '../../assets/img/BagguAndLiv/LivWeb_GraceBeck-17.JPG';
import img18 from '../../assets/img/BagguAndLiv/LivWeb_GraceBeck-18.JPG';
import img19 from '../../assets/img/BagguAndLiv/LivWeb_GraceBeck-19.JPG';

import arrow from '../../assets/arrow2.png';
import arrowdown from '../../assets/arrowdown.png';

export default function BagguAndLiv(props) {

    const [opened, setOpened] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();

        if (!opened) {
            setOpened(true);
        } else {
            setOpened(false);
        }
    }

    return ( 
        <div className="used-project">
            <div className="box1">
                <img alt="" className="sm" src={img1}></img>
                <img alt="" className="sm" src={img2}></img>
                <img alt="" className="xxl" src={img3}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img4}></img>
                <img alt="" className="medium" src={img5}></img>
                <img alt="" className="sm" src={img6}></img>
            </div>
            <div className="box1">
                <img alt="" className="medium" src={img7}></img>
                <img alt="" className="medium" src={img8}></img>
                <img alt="" className="medium" src={img9}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img10}></img>
                <img alt="" className="medium" src={img11}></img>
                <img alt="" className="sm" src={img12}></img>
            </div>
            <div className="box1">
                <img alt="" className="medium" src={img13}></img>
                <img alt="" className="medium" src={img14}></img>
                <img alt="" className="sm" src={img15}></img>
            </div>
            <div className="box1">
                <img alt="" className="medium" src={img16}></img>
                <img alt="" className="sm" src={img17}></img>
                <img alt="" className="medium" src={img18}></img>
            </div>
            <div className="box1">
                <img alt="" className="medium" id="bottom-pic" src={img19}></img>
            </div>
            <footer className="projfoot">
                <div className="proj-foot-div">
                    <div className={opened ? "opened":"closed active"}>
                        
                        <button className="details-btn" onClick={handleClick}><img alt="clickable arrow" src={opened ? arrowdown : arrow }></img></button>
                        <h4 id="foot-head" className="footer-header">Baggu x Liv — Photography + Styling</h4>
                        <div className="footer-content">
                            <p>
                            <br />
                            
                            Model: Liv Fleet 
                            <br />
                            Photo + Styling: Grace Beck 
                            <br />
                            Feature: Baggu
                            <br />

                            <br />
                            </p> 
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
};