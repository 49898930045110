import React, { useState } from 'react';
import emailjs from 'emailjs-com';


export default function Form(props) {

    const [sent, setSent] = useState(false);
        

        const sendEmail = (e) => {

            e.preventDefault();

            emailjs.sendForm('gmail', 'contact_form', e.target, 'user_oZXsoziBOvNysW8Kl5gqS')
            .then((result) => {
                console.log("We have hit this");
                setSent(true)
                resetForm();

                console.log(result.text);
            }, (error) => {
                console.log(error.text);
                console.log("Message not sent")
            });
        }

        // for resetting initial data
            const resetForm = () => {
                setTimeout(() => {
                    setSent(false)
                }, 3000)
                // setSent(false);
                document.getElementById("name").value = "";
                document.getElementById("lastname").value = "";
                document.getElementById("email").value = "";
                document.getElementById("subject").value = "";
                document.getElementById("message").value = "";

                
            }

        return (

            <div className="wholecontact">
                <div className="container">
                    <div className="contact">CONTACT</div>
                    <form id="myform" className="my-form" onSubmit={sendEmail}>
                        <div className="singleItem">
                            <label htmlFor="name">Name *</label>
                            <input type="text" name="user_name" id="name" className="name" placeholder="First Name"  required />
                            <input type="text" name="lastname" lastname="lastname" id="lastname" className="lastname" placeholder="Last Name" required />
                        </div>
                        <div className="singleItem">
                            <label htmlFor="email">Email *</label>
                            <input type="text" name="email" email="email" id="email" className="email" required />
                        </div>
                        <div className="singleItem">
                            <label htmlFor="subject">Subject *</label>
                            <input type="text" name="subject" subject="subject" id="subject" className="subject" required />
                        </div>
                        <div className="singleItem">
                            <label htmlFor="message">Message *</label>
                            <textarea name="message" cols="50" rows="10" type="text" id="message" message="message" className="message" required />
                        </div>
                        <div className={sent ? "msgAppear":"msg"}>Message has been sent</div>
                        <div className={"btn"}>
                            <input type="submit" value="SUBMIT" />
                        </div>
                    </form>
                </div>
                <div className="ball-image">
                    <img alt="blue circle" src="https://static1.squarespace.com/static/557b01ece4b08cdd585e0326/t/5e5ffb8fb8a57c10821c8ef1/1583348629943/youll-only-be-able-to-see-everything-in-this-circ-2-23176-1487024764-4_dblbig.jpg?format=1000w" className="blueball"></img>
                </div>
            </div>
        )
}
