import React, { useState } from 'react';
import img1 from '../../assets/img/IDUN/idun1.JPG';
import img2 from '../../assets/img/IDUN/idun2.JPG';
import img3 from '../../assets/img/IDUN/idun3.JPG';
import img4 from '../../assets/img/IDUN/idun4.JPG';
import img5 from '../../assets/img/IDUN/idun5.JPG';
import img6 from '../../assets/img/IDUN/idun6.JPG';
import img7 from '../../assets/img/IDUN/idun7.JPG';
import img8 from '../../assets/img/IDUN/idun8.JPG';
import img9 from '../../assets/img/IDUN/idun9.JPG';
import img10 from '../../assets/img/IDUN/idun10.JPG';
import img11 from '../../assets/img/IDUN/idun11.JPG';
import img12 from '../../assets/img/IDUN/idun12.JPG';
import img13 from '../../assets/img/IDUN/idun13.JPG';
import img14 from '../../assets/img/IDUN/idun14.JPG';
import img15 from '../../assets/img/IDUN/idun15.JPG';
import img16 from '../../assets/img/IDUN/idun16.JPG';
import img17 from '../../assets/img/IDUN/idun17.JPG';
import img18 from '../../assets/img/IDUN/idun18.JPG';
import img19 from '../../assets/img/IDUN/idun19.JPG';
import img20 from '../../assets/img/IDUN/idun20.JPG';
import img21 from '../../assets/img/IDUN/idun21.JPG';
import img22 from '../../assets/img/IDUN/idun22.JPG';
import img23 from '../../assets/img/IDUN/idun23.JPG';
import img24 from '../../assets/img/IDUN/idun24.JPG';

import arrow from '../../assets/arrow2.png';
import arrowdown from '../../assets/arrowdown.png';



export default function Idun(props) {

    const [opened, setOpened] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();

        if (!opened) {
            setOpened(true);
        } else {
            setOpened(false);
        }
    }

    return ( 
        <div className="used-project">
            <div className="box1">
                <img alt="" className="xxlarge" src={img1}></img>
                <img alt="" className="xxl" src={img2}></img>
                <img alt="" className="xxl" src={img3}></img>
            </div>
            <div className="box1">
                <img alt="" className="medium" src={img4}></img>
                <img alt="" className="xxl" src={img5}></img>
                <img alt="" className="sm" src={img6}></img>
            </div>
            <div className="box1">
                <img alt="" className="justundermed" src={img7}></img>
                <img alt="" className="medium" src={img8}></img>
                <img alt="" className="sm" src={img9}></img>
            </div>
            <div className="box1">
                <img alt="" className="smish" src={img10}></img>
                <img alt="" className="sm" src={img11}></img>
                <img alt="" className="sm" src={img12}></img>
            </div>
            <div className="box1">
                <img alt="" className="smish" src={img13}></img>
                <img alt="" className="midjust" src={img14}></img>
                <img alt="" className="sm" src={img15}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img16}></img>
                <img alt="" className="midjust" src={img17}></img>
                <img alt="" className="medi" src={img18}></img>
            </div>
            <div className="box1">
                <img alt="" className="xxxl" src={img19}></img>
                <img alt="" className="midjust" src={img20}></img>
                <img alt="" className="midjust" src={img21}></img>
            </div>
            <div className="box1">
                <img alt="" className="medi" src={img22}></img>
                <img alt="" className="medi" src={img23}></img>
                <img alt="" className="justoversmed" id="bottom-pic" src={img24}></img>
            </div>

            <footer className="projfoot">
                <div className="proj-foot-div">
                    <div className={opened ? "opened":"closed active"}>
                        
                        <button className="details-btn" onClick={handleClick}><img alt="clickable arrow" src={opened ? arrowdown : arrow }></img></button>
                        <h4 id="foot-head" className="footer-header">IDUN — Styling </h4>
                        <div className="footer-content">
                            <p>
                            <br />
                            
                            Photography: Gene Pease / Havner 
                            <br />
                            Styling: Grace Beck 
                            <br />
                            Models: Nelle Doux and Josie Jensen 
                            <br />
                            Wardrobe Feature: <a href="https://shopidun.com/" className="web-link" target="noopener">IDUN</a>
                            <br />
                            <br />
                            </p> 
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
};