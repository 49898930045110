import React, { useState } from 'react';
import img1 from '../../assets/img/Jon_2/Jon1.jpg';
import img2 from '../../assets/img/Jon_2/Jon2.jpg';
import img3 from '../../assets/img/Jon_2/Jon3.jpg';
import img4 from '../../assets/img/Jon_2/Jon4.jpg';
import img5 from '../../assets/img/Jon_2/Jon5.jpg';
import img6 from '../../assets/img/Jon_2/Jon6.jpg';
import img7 from '../../assets/img/Jon_2/Jon7.jpg';
import img8 from '../../assets/img/Jon_2/Jon8.jpg';
import img9 from '../../assets/img/Jon_2/Jon9.jpg';
import img10 from '../../assets/img/Jon_2/Jon10.jpg';
import img11 from '../../assets/img/Jon_2/Jon11.jpg';
import img12 from '../../assets/img/Jon_2/Jon12.jpg';
import img13 from '../../assets/img/Jon_2/Jon13.jpg';
import img14 from '../../assets/img/Jon_2/Jon14.jpg';
import img15 from '../../assets/img/Jon_2/Jon15.jpg';
import img16 from '../../assets/img/Jon_2/Jon16.jpg';
import img17 from '../../assets/img/Jon_2/Jon17.jpg';
import img18 from '../../assets/img/Jon_2/Jon18.jpg';
import img19 from '../../assets/img/Jon_2/Jon19.jpg';

import arrow from '../../assets/arrow2.png';
import arrowdown from '../../assets/arrowdown.png';

export default function Jon(props) {

    const [opened, setOpened] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();

        if (!opened) {
            setOpened(true);
        } else {
            setOpened(false);
        }
    }

    return ( 
        <div className="used-project">
            <div className="box1">
                <img alt="" className="sm" src={img1}></img>
                <img alt="" className="sm" src={img2}></img>
                <img alt="" className="med" src={img3}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img4}></img>
                <img alt="" className="sm" src={img5}></img>
                <img alt="" className="sm" src={img6}></img>
            </div>
            <div className="box1">
                <img alt="" className="unknown" src={img7}></img>
                <img alt="" className="med" src={img8}></img>
                <img alt="" className="sm" src={img9}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img10}></img>
                <img alt="" className="unknown" src={img11}></img>
                <img alt="" className="sm" src={img12}></img>
            </div>
            <div className="box1">
                <img alt="" className="xl" src={img13}></img>
                <img alt="" className="lg" src={img14}></img>
                <img alt="" className="ml" src={img15}></img>
            </div>
            <div className="box1">
                <img alt="" className="med" src={img16}></img>
                <img alt="" className="med" src={img17}></img>
                <img alt="" className="sml" src={img18}></img>
            </div>
            <div className="box1">
                <img alt="" className="ml" id="bottom-pic" src={img19}></img>
            </div>
            <footer className="projfoot">
                <div className="proj-foot-div">
                    <div className={opened ? "opened":"closed active"}>
                        
                        <button className="details-btn" onClick={handleClick}><img alt="clickable arrow" src={opened ? arrowdown : arrow }></img></button>
                        <h4 id="foot-head" className="footer-header">Jon x Havner — Styling</h4>
                        <div className="footer-content">
                            <p>
                            <br />
                            Photographer: Gene Pease / Havner 
                            <br />
                            Model: Jon Rosemond 
                            <br />
                            Stylist: Grace Beck

                            <br />
                            <br />
                            </p> 
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
};