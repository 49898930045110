import React, { useState } from 'react';
import img1 from '../../assets/img/SteadyPour/sp1.JPG';
import img2 from '../../assets/img/SteadyPour/sp2.JPG';
import img3 from '../../assets/img/SteadyPour/sp3.JPG';
import img4 from '../../assets/img/SteadyPour/sp4.JPG';
import img5 from '../../assets/img/SteadyPour/sp5.JPG';
import img6 from '../../assets/img/SteadyPour/sp6.JPG';
import img7 from '../../assets/img/SteadyPour/sp7.JPG';
import img8 from '../../assets/img/SteadyPour/sp8.JPG';
import img9 from '../../assets/img/SteadyPour/sp9.JPG';
import img10 from '../../assets/img/SteadyPour/sp10.JPG';
import img11 from '../../assets/img/SteadyPour/sp11.JPG';
import img12 from '../../assets/img/SteadyPour/sp12.JPG';
import img13 from '../../assets/img/SteadyPour/sp13.JPG';
import img14 from '../../assets/img/SteadyPour/sp14.JPG';
import img15 from '../../assets/img/SteadyPour/sp15.JPG';
import img16 from '../../assets/img/SteadyPour/sp16.JPG';
import img17 from '../../assets/img/SteadyPour/sp17.JPG';
import img18 from '../../assets/img/SteadyPour/sp18.JPG';
import img19 from '../../assets/img/SteadyPour/sp19.JPG';
import img20 from '../../assets/img/SteadyPour/sp20.JPG';
import img21 from '../../assets/img/SteadyPour/sp21.JPG';
import img22 from '../../assets/img/SteadyPour/sp22.JPG';
import img23 from '../../assets/img/SteadyPour/sp23.JPG';
import img24 from '../../assets/img/SteadyPour/sp24.JPG';
import img25 from '../../assets/img/SteadyPour/sp25.JPG';
import img26 from '../../assets/img/SteadyPour/sp26.JPG';
import img27 from '../../assets/img/SteadyPour/sp27.JPG';
import img28 from '../../assets/img/SteadyPour/sp28.JPG';
import img29 from '../../assets/img/SteadyPour/sp29.JPG';
import img30 from '../../assets/img/SteadyPour/sp30.JPG';
import img31 from '../../assets/img/SteadyPour/sp31.JPG';
import img32 from '../../assets/img/SteadyPour/sp32.JPG';
import img33 from '../../assets/img/SteadyPour/sp33.JPG';
import img34 from '../../assets/img/SteadyPour/sp34.JPG';
import img35 from '../../assets/img/SteadyPour/sp35.JPG';
import img36 from '../../assets/img/SteadyPour/sp36.JPG';
import img37 from '../../assets/img/SteadyPour/sp37.JPG';
import img38 from '../../assets/img/SteadyPour/sp38.JPG';
import img39 from '../../assets/img/SteadyPour/sp39.JPG';
import img40 from '../../assets/img/SteadyPour/sp40.JPG';
import img41 from '../../assets/img/SteadyPour/sp41.JPG';
import img42 from '../../assets/img/SteadyPour/sp42.JPG';
import img43 from '../../assets/img/SteadyPour/sp43.JPG';
import img44 from '../../assets/img/SteadyPour/sp44.JPG';
import img45 from '../../assets/img/SteadyPour/sp45.JPG';
import img46 from '../../assets/img/SteadyPour/sp46.JPG';
import img47 from '../../assets/img/SteadyPour/sp47.JPG';
import img48 from '../../assets/img/SteadyPour/sp48.JPG';
import img49 from '../../assets/img/SteadyPour/sp49.JPG';

import arrow from '../../assets/arrow2.png';
import arrowdown from '../../assets/arrowdown.png';


export default function Idun(props) {

    const [opened, setOpened] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();

        if (!opened) {
            setOpened(true);
        } else {
            setOpened(false);
        }
    }

    return ( 
        <div className="used-project">
            <div className="box1">
                <img alt="" className="medium" src={img1}></img>
                <img alt="" className="medium" src={img2}></img>
                <img alt="" className="medium" src={img3}></img>
            </div>
            <div className="box1">
                <img alt="" className="medium" src={img4}></img>
                <img alt="" className="medium" src={img5}></img>
                <img alt="" className="medium" src={img6}></img>
            </div>
            <div className="box1">
                <img alt="" className="medium" src={img7}></img>
                <img alt="" className="sqr" src={img8}></img>
                <img alt="" className="medium" src={img9}></img>
            </div>
            <div className="box1">
                <img alt="" className="medium" src={img10}></img>
                <img alt="" className="medium" src={img11}></img>
                <img alt="" className="medium" src={img12}></img>
            </div>
            <div className="box1">
                <img alt="" className="xxl" src={img13}></img>
                <img alt="" className="xxl" src={img14}></img>
                <img alt="" className="xxl" src={img15}></img>
            </div>
            <div className="box1">
                <img alt="" className="medium" src={img16}></img>
                <img alt="" className="medium" src={img17}></img>
                <img alt="" className="medium" src={img18}></img>
            </div>
            <div className="box1">
                <img alt="" className="medium" src={img19}></img>
                <img alt="" className="sm" src={img20}></img>
                <img alt="" className="sm" src={img21}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img22}></img>
                <img alt="" className="sm" src={img23}></img>
                <img alt="" className="sm" src={img24}></img>
            </div>
            <div className="box1">
                <img alt="" className="xxl" src={img25}></img>
                <img alt="" className="sm" src={img26}></img>
                <img alt="" className="sm" src={img27}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img28}></img>
                <img alt="" className="sm" src={img29}></img>
                <img alt="" className="sm" src={img30}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img31}></img>
                <img alt="" className="sm" src={img32}></img>
                <img alt="" className="sm" src={img33}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img34}></img>
                <img alt="" className="sm" src={img35}></img>
                <img alt="" className="sm" src={img36}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img37}></img>
                <img alt="" className="sm" src={img38}></img>
                <img alt="" className="xxl" src={img39}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img40}></img>
                <img alt="" className="xxl" src={img41}></img>
                <img alt="" className="xxl" src={img42}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img43}></img>
                <img alt="" className="xxl" src={img44}></img>
                <img alt="" className="sm" src={img45}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img46}></img>
                <img alt="" className="sm" src={img47}></img>
                <img alt="" className="xxl" src={img48}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" id="bottom-pic" src={img49}></img>
            </div>
            <footer className="projfoot">
                <div className="proj-foot-div">
                    <div className={opened ? "opened":"closed active"}>
                        
                        <button className="details-btn" onClick={handleClick}><img alt="clickable arrow" src={opened ? arrowdown : arrow }></img> </button>
                        <h4 id="foot-head" className="footer-header">Steady Pour — Photography + Styling</h4>
                        <div className="footer-content">
                            <p>
                            <br />
                            Campaigns shot and styled for Steady Pour’s social media, new website, and the launch of their at home cocktail kits.
                            <br />
                            <br />
                            <a href="https://www.steadypour.com/" className="web-link" target="noopener">https://www.steadypour.com/</a>
                            <br />
                            <br />
                            <br />
    
                            
                            </p> 
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
};