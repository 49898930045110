import React from 'react';
import Form from './Form';

export default function Contact(props) {
    
    
    return (
        <div className="form-background">
            <Form />
        </div>
    )
};