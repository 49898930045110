import React, { useState } from 'react';
import img1 from '../../assets/img/Art_Show_1/.used1.JPG.icloud';
import img2 from '../../assets/img/Art_Show_1/used2.JPG';
import img3 from '../../assets/img/Art_Show_1/used3.JPG';
import img4 from '../../assets/img/Art_Show_1/used4.JPG';
import img5 from '../../assets/img/Art_Show_1/used5.JPG';
import img6 from '../../assets/img/Art_Show_1/used6.JPG';
import img7 from '../../assets/img/Art_Show_1/used7.JPG';
import img8 from '../../assets/img/Art_Show_1/used8.JPG';
import img9 from '../../assets/img/Art_Show_1/used9.JPG';
import img10 from '../../assets/img/Art_Show_1/used10.JPG';
import img11 from '../../assets/img/Art_Show_1/used11.JPG';
import img12 from '../../assets/img/Art_Show_1/used12.JPG';
import img13 from '../../assets/img/Art_Show_1/used13.JPG';
import img14 from '../../assets/img/Art_Show_1/used14.JPG';
import img15 from '../../assets/img/Art_Show_1/used15.JPG';
import img16 from '../../assets/img/Art_Show_1/.used16.JPG.icloud';
import img17 from '../../assets/img/Art_Show_1/used17.JPG';
import img18 from '../../assets/img/Art_Show_1/used18.JPG';
import img19 from '../../assets/img/Art_Show_1/used19.JPG';
import img20 from '../../assets/img/Art_Show_1/used20.JPG';
import img21 from '../../assets/img/Art_Show_1/used21.JPG';
import img22 from '../../assets/img/Art_Show_1/usedsoloshow1.JPG';
import img23 from '../../assets/img/Art_Show_1/usedsoloshow2.JPG';
import img24 from '../../assets/img/Art_Show_1/usedsoloshow3.JPG';
import img25 from '../../assets/img/Art_Show_1/usedsoloshow4.JPG';
import img26 from '../../assets/img/Art_Show_1/usedsoloshow5.JPG';
import img27 from '../../assets/img/Art_Show_1/usedsoloshow6.JPG';
import img28 from '../../assets/img/Art_Show_1/usedsoloshow7.JPG';
import img29 from '../../assets/img/Art_Show_1/usedsoloshow8.JPG';
import img30 from '../../assets/img/Art_Show_1/usedsoloshow9.JPG';
import img31 from '../../assets/img/Art_Show_1/usedsoloshow10.JPG';
import img32 from '../../assets/img/Art_Show_1/usedsoloshow11.JPG';
import img33 from '../../assets/img/Art_Show_1/usedsoloshow12.JPG';
import img34 from '../../assets/img/Art_Show_1/usedsoloshow13.JPG';
import img35 from '../../assets/img/Art_Show_1/usedsoloshow14.JPG';
import img36 from '../../assets/img/Art_Show_1/usedsoloshow15.JPG';
import img37 from '../../assets/img/Art_Show_1/usedsoloshow16.JPG';
import img38 from '../../assets/img/Art_Show_1/usedsoloshow17.JPG';
import img39 from '../../assets/img/Art_Show_1/usedsoloshow18.JPG';
import img40 from '../../assets/img/Art_Show_1/usedsoloshow19.JPG';
import img41 from '../../assets/img/Art_Show_1/usedsoloshow20.JPG';

import arrow from '../../assets/arrow2.png';
import arrowdown from '../../assets/arrowdown.png';

export default function Used(props) {

    const [opened, setOpened] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();

        if (!opened) {
            setOpened(true);
        } else {
            setOpened(false);
        }
    }

    return ( 
        <div className="used-project">
            <div className="box1">
                <img alt="" className="med" src={img1}></img>
                <img alt="" className="sm" src={img2}></img>
                <img alt="" className="sm" src={img3}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img4}></img>
                <img alt="" className="sm" src={img5}></img>
                <img alt="" className="sm" src={img6}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img7}></img>
                <img alt="" className="lg" src={img8}></img>
                <img alt="" className="sm" src={img9}></img>
            </div>
            <div className="box1">
                <img alt="" className="lg" src={img10}></img>
                <img alt="" className="sm" src={img11}></img>
                <img alt="" className="sm" src={img12}></img>
            </div>
            <div className="box1">
                <img alt="" className="med" src={img13}></img>
                <img alt="" className="med" src={img14}></img>
                <img alt="" className="med" src={img15}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img16}></img>
                <img alt="" className="med" src={img17}></img>
                <img alt="" className="med" src={img18}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img19}></img>
                <img alt="" className="med" src={img20}></img>
                <img alt="" className="sm" src={img21}></img>
            </div>
            <div className="box1">
                <img alt="" className="lg" src={img22}></img>
                <img alt="" className="med" src={img23}></img>
                <img alt="" className="lg" src={img24}></img>
            </div>
            <div className="box1">
                <img alt="" className="med" src={img25}></img>
                <img alt="" className="med" src={img26}></img>
                <img alt="" className="med" src={img27}></img>
            </div>
            <div className="box1">
                <img alt="" className="med" src={img28}></img>
                <img alt="" className="med" src={img29}></img>
                <img alt="" className="med" src={img30}></img>
            </div>
            <div className="box1">
                <img alt="" className="lg" src={img31}></img>
                <img alt="" className="lg" src={img32}></img>
                <img alt="" className="med" src={img33}></img>
            </div>
            <div className="box1">
                <img alt="" className="lg" src={img34}></img>
                <img alt="" className="lg" src={img35}></img>
                <img alt="" className="med" src={img36}></img>
            </div>
            <div className="box1">
                <img alt="" className="med" src={img37}></img>
                <img alt="" className="med" src={img38}></img>
                <img alt="" className="lg" src={img39}></img>
            </div>
            <div className="box1">
                <img alt="" className="med" src={img40}></img>
                <img alt="" className="med" id="bottom-pic" src={img41}></img>
                
            </div>
            <footer className="projfoot">
                <div className="proj-foot-div">
                    <div className={opened ? "opened":"closed active"}>
                        
                        <button className="details-btn" onClick={handleClick}><img alt="clickable arrow" src={opened ? arrowdown : arrow }></img></button>
                        <h4 id="foot-head" className="footer-header">USED: SOLO SHOW</h4>
                        <div id="used-ftr-content" className="footer-content">
                            <p>
                            
                            Le Meridien Gallery | Minneapolis, MN | January - June 2020                            <br />
                            <br />
                            Used: is an exploration and meditation on the life of objects long after they are discarded. The presence of trash in our backyards, city alleyways and homes, easily melt into the background of daily overstimulation. Upon slowing down, noticing and cataloging these items, Grace began to explore the relationship between trash and perceived beauty. This body of work is the result of that relationship as it pertains to fashion, urban landscape and spaces both quiet and populated. Grace is inspired by the concept of revisiting familiar, routine spaces and processing them slowly and intentionally. The outcome is a series of the recycling of not only objects but ideas, colors, memories and moments we might not assign meaning to if we did not stop to truly see them.                            <br />
                            
                            </p> 
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
};