import React, { useState } from 'react';
import img1 from '../../assets/img/stilly/stilly1.JPG';
import img2 from '../../assets/img/stilly/stilly2.JPG';
import img3 from '../../assets/img/stilly/stilly3.JPG';
import img4 from '../../assets/img/stilly/stilly4.JPG';
import img5 from '../../assets/img/stilly/stilly5.JPG';
import img6 from '../../assets/img/stilly/stilly6.JPG';
import img7 from '../../assets/img/stilly/stilly7.JPG';
import img8 from '../../assets/img/stilly/stilly8.JPG';
import img9 from '../../assets/img/stilly/stilly9.JPG';
import img10 from '../../assets/img/stilly/stilly10.JPG';
import img11 from '../../assets/img/stilly/stilly11.JPG';
import img12 from '../../assets/img/stilly/stilly12.JPG';
import img13 from '../../assets/img/stilly/stilly13.JPG';
import img14 from '../../assets/img/stilly/stilly14.JPG';
import img15 from '../../assets/img/stilly/stilly15.JPG';
import img16 from '../../assets/img/stilly/stilly16.JPG';
import img17 from '../../assets/img/stilly/stilly17.JPG';

import arrow from '../../assets/arrow2.png';
import arrowdown from '../../assets/arrowdown.png';

export default function Stilly(props) {

    const [opened, setOpened] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();

        if (!opened) {
            setOpened(true);
        } else {
            setOpened(false);
        }
    }

    return ( 
        <div className="used-project">
            <div className="box1">
                <div className="stilly-proj-pic premidjust">
                    <img alt="" className="premidjust" src={img1}></img>
                </div>
                <div className="stilly-proj-pic premidjust">
                    <img alt="" className="premidjust" src={img2}></img>
                </div>
                <div className="stilly-proj-pic premidjust">
                    <img alt="" className="premidjust" src={img3}></img>
                </div>
            </div>
            <div className="box1">
                <div className="stilly-proj-pic xxl">
                    <img alt="" className="xxl" src={img4}></img>
                </div>
                <div className="stilly-proj-pic biggest">
                    <img alt="" className="biggest" src={img5}></img>
                </div>
                <div className="stilly-proj-pic biggest">
                    <img alt="" className="biggest" src={img6}></img>
                </div>
            </div>
            <div className="box1">
                <div className="stilly-proj-pic premidjust">
                    <img alt="" className="premidjust" src={img7}></img>
                </div>
                <div className="stilly-proj-pic premidjust">
                    <img alt="" className="premidjust" src={img8}></img>
                </div>
                <div className="stilly-proj-pic premidjust">
                    <img alt="" className="premidjust" src={img9}></img>
                </div>
            </div>
            <div className="box1">
                <div className="stilly-proj-pic premidjust">
                    <img alt="" className="premidjust" src={img10}></img>
                </div>
                <div className="stilly-proj-pic medium">
                    <img alt="" className="medium" src={img11}></img>
                </div>
                <div className="stilly-proj-pic medium">
                    <img alt="" className="medium" src={img12}></img>
                </div>
            </div>
            <div className="box1">
                <div className="stilly-proj-pic xxl">
                    <img alt="" className="xxl" src={img13}></img>
                </div>
                <div className="stilly-proj-pic medium">
                    <img alt="" className="medium" src={img14}></img>
                </div>
                <div className="stilly-proj-pic medium">
                    <img alt="" className="medium" src={img15}></img>
                </div>
            </div>
            <div className="box1">
                <div className="stilly-proj-pic medium">
                    <img alt="" className="medium" src={img16}></img>
                </div>
                <div className="stilly-proj-pic medium">
                    <img alt="" className="medium" id="bottom-pic" src={img17}></img>
                </div>
            </div>
            <footer className="projfoot">
            <div className="proj-foot-div">
                <div className={opened ? "opened":"closed active"}>
                    
                    <button className="details-btn" onClick={handleClick}><img alt="clickable arrow" src={opened ? arrowdown : arrow }></img> </button>
                    <h4 id="foot-head" className="footer-header">Stilly CBD — Photography + Styling </h4>
                    <div className="footer-content">
                        <p>
                        <br />
                        Photo and styling for the launch of Stilly CBD’s new website, as well as continued social media content.
                        <br />
                        <br />
                        <a href="https://www.stillycbd.com/" className="web-link" target="noopener">https://www.stillycbd.com/</a>
                        <br />
                        <br />
 
                        
                        </p> 
                    </div>
                </div>
            </div>
        </footer>
        </div>
    )
};