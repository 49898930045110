import React from 'react';
import img1 from '../../assets/img/ALC/ALC_GKB_Web_-1.JPG';
import img2 from '../../assets/img/ALC/ALC_GKB_Web_-2.JPG';
import img3 from '../../assets/img/ALC/ALC_GKB_Web_-3.JPG';
import img4 from '../../assets/img/ALC/ALC_GKB_Web_-4.JPG';
import img5 from '../../assets/img/ALC/ALC_GKB_Web_-5.JPG';
import img6 from '../../assets/img/ALC/ALC_GKB_Web_-6.JPG';
import img7 from '../../assets/img/ALC/ALC_GKB_Web_-7.JPG';
import img8 from '../../assets/img/ALC/ALC_GKB_Web_-8.JPG';
import img9 from '../../assets/img/ALC/ALC_GKB_Web_-9.JPG';
import img10 from '../../assets/img/ALC/ALC_GKB_Web_-10.JPG';
import img11 from '../../assets/img/ALC/ALC_GKB_Web_-11.JPG';
import img12 from '../../assets/img/ALC/ALC_GKB_Web_-12.JPG';


import ProjectFooter from './ProjectFooter';


export default function ALC(props) {

    return ( 
        <div className="used-project">
            <div className="box1">
                <img alt="" className="medium" src={img1}></img>
                <img alt="" className="medium" src={img2}></img>
                <img alt="" className="medium" src={img3}></img>
            </div>
            <div className="box1">
                <img alt="" className="medium" src={img4}></img>
                <img alt="" className="medium" src={img5}></img>
                <img alt="" className="medium" src={img6}></img>
            </div>
            <div className="box1">
                <img alt="" className="medium" src={img7}></img>
                <img alt="" className="medium" src={img8}></img>
                <img alt="" className="medium" src={img9}></img>
            </div>
            <div className="box1">
                <img alt="" className="medium" src={img10}></img>
                <img alt="" className="medium" src={img11}></img>
                <img alt="" className="medium" id="bottom-pic" src={img12}></img>
            </div>
            <footer className="projfoot"><ProjectFooter /></footer>
        </div>
    )
};