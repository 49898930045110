import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Projects from './pages/landing/Landing';
import Used from './pages/project/Used';
import NewZealand from './pages/project/NewZealand';
import Jon from './pages/project/Jon';
import Poorwill from './pages/project/Poorwill';
import Nourished from './pages/project/Nourished';
import Rich from './pages/project/Rich';
import Vancouver from './pages/project/Vancouver';
import Works from './pages/works/Work';
import ALC from './pages/project/ALC';
import EekZine from './pages/project/Eek';
import Elise from './pages/project/Elise';
import Idun from './pages/project/Idun';
import SteadyPour from './pages/project/SteadyPour';
import BagguAndLiv from './pages/project/BagguAndLiv'
import BoyNeonXRosaRanger from './pages/project/BoyneonxRosaRanger'
import Stilly from './pages/project/Stilly';
import TandT from './pages/project/TandT';
import BlueTape from './pages/project/BlueTape';

import About from './pages/grace/About';
import Contact from './pages/grace/Contact';

export default function Content(props) {
    return (
        <div>
            <Switch>
                <Route exact path="/" component={Projects} />
                <Route path="/project/used" component={Used} />
                <Route path="/project/newzealand" component={NewZealand} />
                <Route path="/project/bluetape" component={BlueTape} />
                <Route path="/project/jon" component={Jon} />
                <Route path="/project/nourished" component={Nourished} />
                <Route path="/project/poorwill" component={Poorwill} />
                <Route path="/project/rich" component={Rich} />
                <Route path="/project/vancouver" component={Vancouver} />
                <Route path="/works" component={Works} />
                <Route path="/project/alc" component={ALC} />
                <Route path="/project/eekzine" component={EekZine} />
                <Route path="/project/elise" component={Elise} />
                <Route path="/project/steadypour" component={SteadyPour} />
                <Route path="/project/idun" component={Idun} />
                <Route path="/project/bagguxliv" component={BagguAndLiv} />
                <Route path="/project/boyneonrosaranger" component={BoyNeonXRosaRanger} />
                <Route path="/project/stilly" component={Stilly} />
                <Route path="/project/tandt" component={TandT} />

                <Route path="/about" component={About} />
                <Route path="/contact" component={Contact} />
            </Switch>
        </div>
    )

}