import React from 'react';
import { Link } from "react-router-dom";
import img1 from '../../assets/img/ALC/ALCHeaderImage-1.JPG';
import img2 from '../../assets/img/EekZine/EEKHeaderImage-1.JPG';
import img3 from '../../assets/img/Elise/Elise12.jpg';
import img4 from '../../assets/img/IDUN/idun16.JPG';
import img5 from '../../assets/img/SteadyPour/SPHeaderImage-1.JPG';
// import img6 from '../../assets/img/Vancouver/v6.JPG';
import img7 from '../../assets/img/BagguAndLiv/BagguHeader-1.JPG';
import img8 from '../../assets/img/BoyneonxRosaRanger/BoyNeonHeaderImage-1-2.JPG';
import img9 from '../../assets/img/stilly/StillyHeaderImage-1.JPG';
import img10 from '../../assets/img/TandT/TTHeaderImahe-1.JPG';

export default function Work(props) {

    return ( 
        <div className="used-project">
            <div className="work1to3">
                <div className="links">
                    <Link to="/project/alc" className="App-link">
                        <img alt="" className="work-sm work" src={img1}></img>
                        <div className="work-caption">ALC</div>
                    </Link>
                </div>
                <div className="links">
                    <Link to="/project/idun" className="App-link">
                        <img alt="" className="work-sm work" src={img4}></img>
                        <div className="work-caption">IDUN</div>
                    </Link>                   
                </div>
                <div className="links">
                    <Link to="/project/stilly" className="App-link">
                        <img alt="" className="work-sm work" src={img9}></img>
                        <div className="work-caption">Stilly</div>
                    </Link>
                </div>
            </div>
            <div className="work1to3">
                <div className="links">
                    <Link to="/project/boyneonrosaranger" className="App-link">
                        <img alt="" className="work-sm work" src={img8}></img>
                        <div className="work-caption">Boyneon x RosaRanger</div>
                    </Link>
                </div>
                <div className="links">
                    <Link to="/project/steadypour" className="App-link">
                        <img alt="" className="work-sm work" src={img5}></img>
                        <div className="work-caption">Steady Pour</div>
                    </Link>
                </div>
                <div className="links">
                    <Link to="/project/bagguxliv" className="App-link">
                        <img alt="" className="work-sm work" src={img7}></img>
                        <div className="work-caption">Baggu x Liv</div>
                    </Link>
                </div>
            </div>
            <div className="work1to3">
                <div className="links">
                    <Link to="/project/eekzine" className="App-link">
                        <img alt="" className="work-sm work" src={img2}></img>
                        <div className="work-caption">EEK! Zine</div>
                    </Link>
                </div>
                <div className="links">
                    <Link to="/project/tandt" className="App-link">
                        <img alt="" className="work-sm work" src={img10}></img>
                        <div className="work-caption">T+T</div>
                    </Link>
                </div>

                <div className="links">
                    <Link to="/project/elise" className="App-link">
                        <img alt="" className="work-sm work" src={img3}></img>
                        <div className="work-caption">Elise</div>
                    </Link>
                </div>
            </div>
        </div>
    )
};