import React from 'react';
import './App.css';
import Content from "./content/Content";
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './wrappers/Header';
import Footer from './wrappers/Footer';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Header />
        </header>
        <body>
            <Content />
        </body>
        <div className="spac"></div>
        <footer>
          <Footer />
        </footer>
      </div>
    </Router>
  );
}

export default App;
