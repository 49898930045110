import React, { useState } from 'react';
import arrow from '../../assets/arrow2.png';
import arrowdown from '../../assets/arrowdown.png';


export default function Header(props) {

    const [opened, setOpened] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();

        if (!opened) {
            setOpened(true);
        } else {
            setOpened(false);
        }
    }

    return (
        
        <div className="proj-foot-div">
            <div className={opened ? "opened":"closed active"}>
                
                <button className="details-btn" onClick={handleClick}><img alt="clickable arrow" src={opened ? arrowdown : arrow }></img></button>
                <h4 id="foot-head" className="footer-header">ALC — Photography + Styling</h4>
                <div className="footer-content">
                    <p>
                    Based in Minneapolis, MN, ALC is an agency that focuses on design and art direction. Their goal is to push their viewers to think deeper than the average white background. These images were created for their social media, with the prompts being “perception” “color” and a study on 3 pieces from each individual's wardrobe. 
                    <br />
                    <br />
                    <a href="https://arilambcreative.com/" className="web-link" target="noopener">arilambcreative.com</a>
                    </p> 
                </div>
            </div>
        </div>
    )
};

