import React from 'react';
import { Link } from "react-router-dom";
import image1 from '../../assets/img/image1.jpeg';
import image2 from '../../assets/img/image2.jpeg';
import image3 from '../../assets/img/image3.jpeg';
import image4 from '../../assets/img/image4.jpeg';
import image5 from '../../assets/img/image5.jpeg';
import image6 from '../../assets/img/image6.jpeg';
import movieImage from '../../assets/img/BlueTapeFilmStills3.jpg'
import image8 from '../../assets/img/image8.jpeg';
// import { Player } from 'video-react';
// import videojs from 'video.js'


export default function Projects(props) {

    return (
        <div className='body'>
            <div className='col1'>
                <div className='small-box'></div>
                <div className='pic-container'>
                    <Link to="/project/used" className="App-link">
                        <img className='pic1' alt="" src={image1} />
                    </Link>
                    
                    <div className='caption1'>Used: Solo Gallery Show Minneapolis, MN</div>
                </div>
                <div className='pic-container'>
                    <Link to="/project/newzealand" className="App-link">
                        <img className='pic5' alt="" src={image5} />
                    </Link>
                    <div className='caption5'>New Zealand</div>
                </div>
                <div className='small-box'></div>
                <div className='pic-container-no-bottom'>
                    <Link to="/project/nourished" className="App-link">
                        <img className='pic8' alt="" src={image8} />
                    </Link>
                    <div className='caption8'>Nourished</div>
                </div>
            </div>
            <div className='square'>
                <div className='col2'>
                    <div className='pic-container'>
                        <Link to="/project/jon" className="App-link">
                            <img className='pic2' alt="" src={image2} />
                        </Link>
                        <div className='caption2'>Havner x GB Styling</div>
                    </div>
                    <div className='pic-container-no-bottom'>
                        <Link to="/project/rich" className="App-link">
                            <img className='pic4' alt="" src={image4} />
                        </Link>
                        <div className='caption4'>Richard in the Spring </div>
                    </div>
                </div>
                <div className='col3'>
                    <div className='medium-box'></div>
                    <div className='small-box'></div>
                    <div className='pic-container'>
                        <Link to="/project/poorwill" className="App-link">
                            <img className='pic3' alt="" src={image3} />
                        </Link>
                        <div className='caption3'>Poorwill</div>
                    </div>
                    <div className='small-box'></div>
                    <div className='pic-container-no-bottom'>
                        <Link to="/project/vancouver" className="App-link">
                            <img className='pic6' alt="" src={image6} />
                        </Link>
                        <div className='caption6'>Vancouver</div>
                    </div>
                </div>
            </div>
            <div className='col4'>
                <div className='pic-container'>
                    <Link to="/project/bluetape" className="App-link">
                            <img className="pic7" src={movieImage} alt="" />
                    </Link>
                    <div className='caption7'>Blue Tape</div>
                </div>
            </div>
        </div>
    )
};

/* <video width="320" height="240" controls>
  <source src="movie.mp4" type="video/mp4">
  <source src="movie.ogg" type="video/ogg">
  Your browser does not support the video tag.
</video> */

/* <Player>
    <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
</Player> */

/* <video-js>
    <source src={movie7} ></source>
    <source src="//vjs.zencdn.net/v/oceans.webm" type="video/webm"></source>
</video-js> */

/* <Player className="pic7">
<source src={movie7} />
</Player> */