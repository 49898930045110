import React from 'react';
import img1 from '../../assets/img/Vancouver/v1.JPG';
import img2 from '../../assets/img/Vancouver/v2.JPG';
import img3 from '../../assets/img/Vancouver/v3.JPG';
import img4 from '../../assets/img/Vancouver/v4.JPG';
import img5 from '../../assets/img/Vancouver/v5.JPG';
import img6 from '../../assets/img/Vancouver/v6.JPG';
import img7 from '../../assets/img/Vancouver/v7.JPG';
import img8 from '../../assets/img/Vancouver/v8.JPG';
import img9 from '../../assets/img/Vancouver/v9.JPG';
import img10 from '../../assets/img/Vancouver/v10.JPG';
import img11 from '../../assets/img/Vancouver/v11.JPG';
import img12 from '../../assets/img/Vancouver/v12.JPG';
import img13 from '../../assets/img/Vancouver/v13.JPG';
import img14 from '../../assets/img/Vancouver/v14.JPG';
import img15 from '../../assets/img/Vancouver/v15.JPG';

export default function Vancouver(props) {

    return ( 
        <div className="used-project">
            <div className="box1">
                <img alt="" className="smallest" src={img1}></img>
                <img alt="" className="smallest" src={img2}></img>
                <img alt="" className="smallest" src={img3}></img>
            </div>
            <div className="box1">
                <img alt="" className="smallest" src={img4}></img>
                <img alt="" className="smallest" src={img5}></img>
                <img alt="" className="smallest" src={img6}></img>
            </div>
            <div className="box1">
                <img alt="" className="smallest" src={img7}></img>
                <img alt="" className="smallest" src={img8}></img>
                <img alt="" className="smallest" src={img9}></img>
            </div>
            <div className="box1">
                <img alt="" className="smallest" src={img10}></img>
                <img alt="" className="smallest" src={img11}></img>
                <img alt="" className="smallest" src={img12}></img>
            </div>
            <div className="box1">
                <img alt="" className="smallest" src={img13}></img>
                <img alt="" className="smallest" src={img14}></img>
                <img alt="" className="smallest" src={img15}></img>
            </div>
        </div>
    )
};