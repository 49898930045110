import React, { useState } from 'react';
import img1 from '../../assets/img/Elise/Elise1.jpg';
import img2 from '../../assets/img/Elise/Elise2.jpg';
import img3 from '../../assets/img/Elise/Elise3.jpg';
import img4 from '../../assets/img/Elise/Elise4.jpg';
import img5 from '../../assets/img/Elise/Elise5.jpg';
import img6 from '../../assets/img/Elise/Elise6.jpg';
import img7 from '../../assets/img/Elise/Elise7.jpg';
import img8 from '../../assets/img/Elise/Elise8.jpg';
import img9 from '../../assets/img/Elise/Elise9.jpg';
import img10 from '../../assets/img/Elise/Elise10.jpg';
import img11 from '../../assets/img/Elise/Elise11.jpg';
import img12 from '../../assets/img/Elise/Elise12.jpg';
import img13 from '../../assets/img/Elise/Elise13.jpg';

import arrow from '../../assets/arrow2.png';
import arrowdown from '../../assets/arrowdown.png';

export default function Elise(props) {

    const [opened, setOpened] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();

        if (!opened) {
            setOpened(true);
        } else {
            setOpened(false);
        }
    }

    return ( 
        <div className="used-project">
            <div className="box1">
                <img alt="" className="sm" src={img1}></img>
                <img alt="" className="sm" src={img2}></img>
                <img alt="" className="sm" src={img3}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img4}></img>
                <img alt="" className="sm" src={img5}></img>
                <img alt="" className="sm" src={img6}></img>
            </div>
            <div className="box1">
                <img alt="" className="xxl" src={img7}></img>
                <img alt="" className="sm" src={img8}></img>
                <img alt="" className="sm" src={img9}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm" src={img10}></img>
                <img alt="" className="sm" src={img11}></img>
                <img alt="" className="sm" src={img12}></img>
            </div>
            <div className="box1">
                <img alt="" className="sm"  id="bottom-pic" src={img13}></img>
            </div>
            <footer className="projfoot">
                <div className="proj-foot-div">
                    <div className={opened ? "opened":"closed active"}>
                        
                        <button className="details-btn" onClick={handleClick}><img alt="clickable arrow" src={opened ? arrowdown : arrow }></img></button>
                        <h4 id="foot-head" className="footer-header">Elise x Havner — Styling</h4>
                        <div className="footer-content">
                            <p>
                            <br />
                            Photography: Gene Pease / Havner 
                            <br />
                            Model: Elise Radspinner 
                            <br />
                            Stylist: Grace Beck
                            <br />
                            <br />
                            </p> 
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
};